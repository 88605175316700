import { useRef, useEffect } from "react"
import "./App.css"
import styled from "styled-components"
import bgImage from "./backgroundPL_night.jpg"
import logo_fb from "./logo_fb.svg"
import logo_ig from "./logo_ig.svg"
import logo_mapy from "./logo_mapy.svg"
import les_sound from "./les_sound.mp3"
import { motion } from "framer-motion"

const App = () => {
  /*   const audio = useRef(new Audio(les_sound))
  useEffect(() => {
    audio.current.loop = true
    audio.current.play()
  }) */
  let audio = new Audio(les_sound)
  useEffect(() => {
    audio.loop = true
    audio.play()
  })

  return (
    <Main>
      <motion.span
        className="title"
        initial={{ opacity: 0, y: "-20vh" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, type: "spring", stiffness: 200 }}
      >
        Pohádkový les Březolupy 2022
      </motion.span>

      <div className="links">
        <motion.div
          className="link fb"
          initial={{ y: "20vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.2 }}
        >
          <a href="https://fb.me/e/2wkcNCwow">
            <motion.img src={logo_fb} alt="" whileHover={{ scale: 1.2 }} />
          </a>
        </motion.div>
        <motion.div
          className="link mapy"
          initial={{ y: "20vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.4 }}
        >
          <a href="https://mapy.cz/s/necurukela">
            <motion.img src={logo_mapy} alt="" whileHover={{ scale: 1.1 }} />
          </a>
        </motion.div>
        <motion.div
          className="link ig"
          initial={{ y: "20vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.6 }}
        >
          <a href="https://www.instagram.com/pohadkovy_les_brezolupy/">
            <motion.img
              src={logo_ig}
              alt=""
              whileHover={{
                scale: 1.15,
              }}
            />
          </a>
        </motion.div>
      </div>

      <motion.span
        className="title"
        initial={{ opacity: 0, y: "-20vh" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, type: "spring", stiffness: 200 }}
      >
        6.8. od 13:30
      </motion.span>
      {/* <span style={{ fontSize: "50px" }}>31.7.</span> */}
    </Main>
  )
}

export default App

const Main = styled.div`
  background-image: url(${bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: Pohadkovylesfont-Regular;
  /*   font-size: 90px; */
  text-align: center;
  width: 100%;
  height: 100vh;
  /*   width: 100%;
  height: 100vh; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: #fff;
  > .title {
    margin: 8vh 0 2vh 0;
    font-size: clamp(3rem, 5vw, 8rem);
  }
  > .links {
    display: flex;
    justify-content: center;
    /* width: 100%; */
    height: clamp(1.6rem, 2.5vw, 4rem);
    margin: 30px 0;
    > .link {
      height: 100%;
      margin: 0 3vw;
      > a {
        height: 100%;
        > img {
          height: 100%;
        }
        > img:hover {
          -webkit-filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.7));
          filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.7));
        }
      }
    }
  }
  @media (max-width: 400px) {
    > .links {
      > .link {
        margin: 0 5vw;
      }
    }
  }
`
